import { MANAGER_TYPES, ROLES } from "@/core/config/AppConfig";

export default {
    path: "provincia/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "provincia",
    meta: {
        guards: {
            types: [MANAGER_TYPES.provincia],
            roles: [ROLES.admin, ROLES.provincia],
        },
    },
    children: [
        {
            path: "",
            name: "provincia.dashboard",
            component: () => import("@/views/DashboardProvincia.vue"),
        },
        {
            path: "accommodations",
            name: "provincia.accommodations",
            component: () =>
                import("@/views/accommodations/admin/AccommodationsList.vue"),
        },
        {
            path: "accommodations/:accommodationId",
            name: "provincia.accommodations.detail",
            component: () =>
                import("@/views/accommodations/admin/AccommodationDetail.vue"),
        },
        {
            path: "accommodations/licenses",
            name: "provincia.accommodations.licenses",
            component: () =>
                import("@/views/accommodations/UnpairedLicensesList.vue"),
        },
        {
            path: "accommodations/license/:licenseId",
            name: "provincia.accommodations.license.new",
            component: () =>
                import("@/views/accommodations/new/NewAccommodation.vue"),
        },
        {
            path: "accommodations/search",
            name: "provincia.accommodations.search",
            component: () =>
                import("@/views/accommodations/SearchAccommodations.vue"),
        },
        {
            path: "enti",
            name: "provincia.enti",
            component: () => import("@/views/licence/ManagersList.vue"),
            props: { type: "enti" },
            children: [
                {
                    path: "new",
                    name: "provincia.enti.create",
                    props: { type: "enti" },
                    component: () =>
                        import("@/views/licence/ManagerCreate.vue"),
                },
            ],
        },
        {
            path: "enti/:managerDetailId",
            name: "provincia.enti.detail",
            component: () => import("@/views/licence/ManagerDetail.vue"),
            children: [
                {
                    path: "users/",
                    name: "provincia.enti.detail.user.create",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
                {
                    path: "users/:userId",
                    name: "provincia.enti.detail.user.edit",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
            ],
        },
        {
            path: "users",
            name: "provincia.users",
            component: () => import("@/views/users/UsersList.vue"),
        },
        {
            path: "users/:userId",
            name: "provincia.users.detail",
            component: () => import("@/views/users/UserDetail.vue"),
        },
        {
            path: "mail_scheduler",
            name: "provincia.mail_scheduler.mail",
            component: () => import("@/views/mail_scheduler/MailList.vue"),
        },
        {
            path: "mail_scheduler/new",
            name: "provincia.mail_scheduler.new",
            component: () => import("@/views/mail_scheduler/MailForm.vue"),
        },
        {
            path: "mail_scheduler/detail/:mailId",
            name: "provincia.mail_scheduler.detail",
            component: () => import("@/views/mail_scheduler/MailForm.vue"),
        },
        {
            path: "mail_scheduler/recipients",
            name: "provincia.mail_scheduler.recipients",
            component: () => import("@/views/mail_scheduler/RecipientList.vue"),
        },
        {
            path: "mail_scheduler/templates",
            name: "provincia.mail_scheduler.templates",
            component: () => import("@/views/mail_scheduler/TemplateList.vue"),
        },
        {
            path: "mail_scheduler/templates/:templateId",
            name: "provincia.mail_scheduler.templates.detail",
            component: () => import("@/views/mail_scheduler/TemplateForm.vue"),
        },
        {
            path: "mail_scheduler/templates/new",
            name: "provincia.mail_scheduler.templates.new",
            component: () => import("@/views/mail_scheduler/TemplateForm.vue"),
        },
        {
            path: "mail_scheduler/queue/:mailId",
            name: "provincia.mail_scheduler.queue",
            component: () => import("@/views/mail_scheduler/Queue.vue"),
        },
        {
            path: "alerts",
            name: "provincia.alerts",
            component: () => import("@/views/alerts/AlertsList.vue"),
        },
        {
            path: "alerts/new",
            name: "provincia.alerts.new",
            component: () => import("@/views/alerts/AlertsForm.vue"),
        },
        {
            path: "alerts/:alertId",
            name: "provincia.alerts.detail",
            component: () => import("@/views/alerts/AlertsForm.vue"),
        },
        {
            path: "rates",
            name: "provincia.rates",
            component: () => import("@/views/rates/RatesList.vue"),
            children: [
                {
                    path: "new",
                    name: "provincia.rates.create",
                    component: () => import("@/views/rates/CreatePeriod.vue"),
                },
                {
                    path: ":periodId",
                    name: "provincia.rates.edit",
                    component: () => import("@/views/rates/CreatePeriod.vue"),
                },
            ],
        },
        /*      {
          path: "rates/setting",
          name: "provincia.rate-setting",
          component: () => import("@/views/crafted/pages/profile/Overview.vue"),
        },*/
        {
            path: "licenses",
            name: "provincia.licenses.archive",
            component: () => import("@/views/licence/LicenceList.vue"),
        },
        {
            path: "licenses/:licenseId",
            name: "provincia.licenses.archive.detail",
            component: () => import("@/views/licence/LicenseDetail.vue"),
            children: [
                {
                    path: "edit",
                    name: "provincia.licenses.archive.detail.edit",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/edit-form/EditLicenseModal.vue"
                        ),
                },
                {
                    path: "suspend",
                    name: "provincia.licenses.archive.detail.suspend",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/suspend/SuspendLicenseModal.vue"
                        ),
                },
                {
                    path: "close",
                    name: "provincia.licenses.archive.detail.close",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/close/CloseLicenseModal.vue"
                        ),
                },
                {
                    path: "note",
                    name: "provincia.licenses.archive.detail.note",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/add-note/AddNote.vue"
                        ),
                },
            ],
        },
        {
            path: "licenses/managers",
            name: "provincia.licenses.managers",
            component: () => import("@/views/licence/ManagersList.vue"),
            props: { type: "hotel" },
            children: [
                {
                    path: "new",
                    name: "provincia.licenses.managers.create",
                    props: { type: "hotel" },
                    component: () =>
                        import("@/views/licence/ManagerCreate.vue"),
                },
            ],
        },
        {
            path: "applications",
            name: "provincia.applications",
            component: () => import("@/views/licence/ApplicationList.vue"),
        },
        {
            path: "license/new_applications",
            name: "provincia.licenses.new_applications",
            component: () => import("@/views/licence/NewApplicationsList.vue"),
        },
        {
            path: "license/new",
            name: "provincia.licenses.new_app_wizard_manual",
            component: () => import("@/views/licence/NewApplicationWizard.vue"),
        },
        {
            path: "license/:licenseId/new",
            name: "provincia.licenses.new_app_wizard_license",
            component: () => import("@/views/licence/NewApplicationWizard.vue"),
        },
        {
            path: "license/new/:scia_code",
            name: "provincia.licenses.new_app_wizard",
            component: () => import("@/views/licence/NewApplicationWizard.vue"),
        },
        {
            path: "license/create",
            name: "provincia.licenses.new_application",
            component: () => import("@/views/licence/NewApplication.vue"),
        },
        {
            path: "licenses/managers/:managerDetailId",
            name: "provincia.licenses.managers.detail",
            component: () => import("@/views/licence/ManagerDetail.vue"),
            children: [
                {
                    path: "users/",
                    name: "provincia.licenses.managers.detail.user.create",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
                {
                    path: "users/:userId",
                    name: "provincia.licenses.managers.detail.user.edit",
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
            ],
        },
        {
            path: "licenses/properties",
            name: "provincia.licenses.properties",
            component: () => import("@/views/licence/PropertiesList.vue"),
        },
        {
            path: "istat",
            name: "provincia.istat.report",
            component: () => import("@/views/istat/ReportRispondenti.vue"),
        },
        {
            path: "istat/report_full",
            name: "provincia.istat.report_full",
            component: () => import("@/views/istat/ReportRispondentiFull.vue"),
        },
        {
            path: "istat/totals",
            name: "provincia.istat.monthly-totals",
            component: () => import("@/views/istat/MonthlyTotalsList.vue"),
        },
        {
            path: "closing-calendar",
            name: "provincia.istat.closing-calendar",
            component: () => import("@/views/istat/ClosingCalendar.vue"),
        },
        {
            path: "istat/big_brother",
            name: "provincia.istat.big-brother",
            component: () => import("@/views/istat/BigBrother.vue"),
        },
        {
            path: "citytax",
            name: "provincia.citytax.summary",
            component: () => import("@/views/citytax/CitytaxSummary.vue"),
        },
        {
            path: "citytax/setup",
            name: "provincia.citytax.setup",
            component: () => import("@/views/citytax/Setup.vue"),
        },
        {
            path: "citytax/rates/",
            name: "provincia.citytax.rates",
            component: () => import("@/views/citytax/Rates.vue"),
        },
        {
            path: "citytax/exemptions",
            name: "provincia.citytax.exemptions",
            component: () => import("@/views/citytax/Exemptions.vue"),
        },
        {
            path: "analytics",
            name: "provincia.analytics",
            component: () =>
                import("@/views/crafted/pages/profile/Overview.vue"),
        },
        {
            path: "analytics/guests",
            name: "provincia.analytics.guest-nights",
            component: () => import("@/views/analytics/ReportGuests.vue"),
        },
        {
            path: "analytics/accommodations",
            name: "provincia.analytics.accommodations",
            component: () =>
                import("@/views/analytics/ReportAccommodations.vue"),
        },
        {
            path: "analytics/origin",
            name: "provincia.analytics.origin",
            component: () => import("@/views/analytics/ReportOrigin.vue"),
        },
        {
            path: "analytics/citytax",
            name: "provincia.analytics.citytax",
            component: () => import("@/views/analytics/ReportCitytax.vue"),
        },
    ],
};
